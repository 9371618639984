<template>
  <van-popup v-model="showSku"  position="bottom" closeable close-on-popstate  @close="handleClose" class="m-sku m-padding">
      <!-- 骨架屏 -->
      <div v-if="isLoading">
        <div class="sku-info">
          <div style="width:110px;height:110px;background:#f7f7f7;margin-right:10px"></div>
          <div class="sku-price">
              <p style="width:60px;height:30px;background:#f7f7f7"></p>
              <p style="width:120px;height:30px;background:#f7f7f7"></p>
          </div>
        </div>
        <div class="sku-value">
          <p class="title" style="width:60px;height:30px;background:#f7f7f7;border:none"></p>
          <div class="values">
            <template v-for="(i, ind) in 3">
              <span style="width:60px;height:30px;background:#f7f7f7;border:none" :key="ind"></span>
            </template>
          </div>
        </div>
        <div class="sku-num">
          <p class="title" style="width:60px;height:30px;background:#f7f7f7"></p>
          <div class="number" style="background:#f7f7f7;height:30px;width:120px"></div>
        </div>
        <div class="sku-button ">
          <van-button type="primary" block color="linear-gradient(315deg,#F03C18,#F06448)">
            加载规格中···
          </van-button>
        </div>
      </div>
      <!-- 显示数据 -->
      <div v-else class="sku-box">
        <div class="sku-info">
          <img :src="skuImg" alt="" @click="imagePreview">
          <div class="sku-price">
              <p>
                <span>$</span><span>{{(goodsPrice - 0).toFixed(2)}}</span>
                <span v-if="isEmpty">现货价：${{(costPrice - 0).toFixed(2)}}</span>
                <span v-if="!isEmpty && costPrice != goodsPrice">${{(costPrice - 0).toFixed(2)}}</span>
              </p>
              <p class="sku-select">已选择：{{selectSku.join(' | ')}}</p>
          </div>
        </div>

        <div class="sku-value" v-for="(it,index) in skuData.attr" :key="index">
          <p class="title">{{it.title}}</p>
          <div class="values" :style="{height:it.val.length > 8 ? 'auto' : 'auto'}">
            <template v-for="(i, ind) in it.val">
              <span :class="{'active':i.active, 'emptyStock': i.emptyStock, 'hidden': i.hidden}" :key="ind"
              @click="handleActive(it, i, index, ind)" v-if="!i.hidden || (index == 0 && skuData.attr.length > 1)">
                {{i.attr_title}}
              </span>
            </template>
          </div>
        </div>

        <!-- 预售弹窗 -->
        <div class="pre-box" v-if="isEmpty">
          <div class="tips">
            <span>*配送:</span>
            <p>该商品预计{{batch.purchase_period + batch.order_period + batch.delivery_date}}个工作日到仓</p>
          </div>
        </div>
        
        <div class="sku-num" v-if="!this.barcode || !this.num">
          <p class="title">数量：</p>
          <div class="number">
            <van-stepper v-model="number" integer :min="1"
            :max="($route.query.pre && $route.query.pre == 1 || preList.indexOf($route.path) != -1 || isEmpty || $route.query.sign == 1) ? 5 :
            (skuStock.sku_stock ? haggle == 1 ? 1 : skuStock.sku_stock.stock : 0)" />
            <span style="margin-left:10px;font-size:12px;color:#888"
            v-if="($route.query.pre && $route.query.pre == 1 || preList.indexOf($route.path) != -1 || isEmpty || $route.query.sign == 1) && number == 5">
              {{$route.query.sign == 1 ? '签到商品最多购买五件' : '预售商品最多可购买五件'}}
            </span>
            <span style="margin-left:10px;font-size:12px;color:#888"
            v-if="!isEmpty && number ==  skuStock?skuStock.sku_stock.stock:''">
              当前商品无更多库存
            </span>
          </div>
        </div>
        <div class="sku-button ">
          <van-button type="primary" block color="linear-gradient(315deg,#F03C18,#F06448)" @click="handleBtn" v-if="goodsId != '3751'">
            {{title}}
          </van-button>
          <van-button type="primary" block color="linear-gradient(315deg,#F03C18,#F06448)" @click="$router.push('/zh/bargain')" v-else>
            获取折扣
          </van-button>
        </div>
      </div>
  </van-popup>
</template>

<script>
import { addCart } from '@/api/zh/cart.js'
import { getSku } from '@/api/zh/detail.js'
import { getBatch } from '@/api/public.js'
import { haggle_detail } from '@/api/zh/haggle.js'
import { my_total } from '@/api/zh/mine.js'
import { ImagePreview } from 'vant'
export default {
  name:"MDivider",
  props: ['title', 'show', 'goodsId', 'barcode', 'num' , 'off', 'haggle'],
  data() {
    return {
      showSku:false,
      number:1,
      skuData: [],
      isEmpty: false, // 当前选择的规格是否有库存
      isHidden: false,  // 当前选择的规格是否有该规格
      skuImg: '',
      goodsPrice: 0,
      costPrice: 0,
      selectSku: [],  // 选择的规格
      skuStock: '',
      skuId: '',
      isLoading: true,
      isPre: false,
      batch: {},
      preList: ['/zh/presale', '/zh/subsale'],
      imgIndex: 0,
      haggle_id:'',
      haggleInfo:{bargain_goods_sku:[]},
      goodsSKU: ''
    }
  },
  methods: {
    // 关闭弹窗触发
    handleClose(){
      this.$emit('update:num', null)
      this.$emit('update:barcode', null)
      this.$emit('close', false)
      this.$emit('update:goodsId', '')
    },
    // 点击变量事件
    handleActive(it, i, ind, k = 0){
      it.val.forEach(e => {
        this.$set(e, 'active', false)
      })
      this.$set(i, 'active', true)


      if(this.skuData.attr.length > 1) {
        this.moreSkuJudge(i, ind)
      } else {
        this.selectSku = []
        this.selectSku.push(i.attr_title)
        
        this.skuData.sku.forEach(el => {
          if(el.attr_symbol_path == i.symbol) {
            this.imgIndex = this.skuData.sku.findIndex(item => {
              return item == el
            })
            if(this.haggle == 1) {
              this.haggleInfo.bargain_goods_sku.forEach(item => {
                if(item.goods_sku.barcode == el.barcode) {
                  this.haggle_id = item.id
                  this.goodsSKU = item
                }
              })
            }
            this.skuImg = el.sku_pic_url
            this.goodsPrice = el.price
            this.costPrice = el.cost_price
            this.skuStock = el 
            this.skuId = el.id
          }
        })

        if(i.emptyStock) {
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      }
      this.$emit('select', {sku: this.selectSku, price: this.goodsPrice, barcode: this.skuData.sku.find(item => item.id == this.skuId).barcode, costPrice: this.costPrice})
      // 强制刷新
      this.$forceUpdate()
    },
    moreSkuJudge(i, ind) {
      // 多规格判断库存
      // 匹配sku  获取价格和库存
      let sym_arr = [], active_arr = []
      let emptyArr = [], hiddenArr = []
      this.selectSku = []
      this.skuData.attr.forEach((el, index) => {
        if(index < ind) {
          el.val.forEach((ll, inn) => {
            sym_arr = []
            sym_arr.push(ll.symbol)
            sym_arr.push(i.symbol)
            this.judgeStock(sym_arr.join(','), index, inn)
          })
        }else if(index > ind) {
          el.val.forEach((ll, inn) => {
            sym_arr = []
            sym_arr.push(i.symbol)
            sym_arr.push(ll.symbol)
            this.judgeStock(sym_arr.join(','), index, inn)
          })
        }

        el.val.forEach(ll => {
          if(ll.active) {
            active_arr.push(ll.symbol)
            this.selectSku.push(ll.attr_title)
          }

          if(ll.emptyStock && ll.active) {
            emptyArr.push(true)
          }

          if(ll.hidden && ll.active) {
            hiddenArr.push(true)
          }
        })
      })
      // 判断是否有库存
      if(emptyArr.length == this.skuData.attr.length) {
        this.isEmpty = true
      }else{
        this.isEmpty = false
      }
      // 判断是否有该规格
      if(hiddenArr.length == this.skuData.attr.length) {
        this.isHidden = true
      }else{
        this.isHidden = false
      }

      this.skuData.sku.forEach(el => {
        if(el.attr_symbol_path == active_arr.join(',')) {
          this.skuStock = el
          this.skuId = el.id
          if(this.haggle == 1) {
            this.haggleInfo.bargain_goods_sku.forEach(item => {
              if(item.goods_sku.barcode == el.barcode) {
                this.haggle_id = item.id
                this.goodsSKU = item
              }
            })
          }
        }
      })
        if ( this.skuStock == 'undefined' || !this.skuStock) {
           this.skuData.sku.forEach(el => {
            if(el.sku_stock.stock) {
              this.skuStock = el
              this.skuId = el.id
            }
         })
      }
    },
    judgeStock(str, index, ind) {
      // 判断库存是否剩余
      let isHidden = true
      this.skuData.attr[index].val[ind].emptyStock = false
      this.skuData.sku.forEach(el => {
        if(str == el.attr_symbol_path) {
          if(this.skuData.attr[index].val[ind].active) {
            this.imgIndex = this.skuData.sku.findIndex(item => {
              return item == el
            })
            this.skuImg = el.sku_pic_url
            this.goodsPrice = el.price
            this.costPrice = el.cost_price
          }

          isHidden = false
          if(!el.sku_stock || el.sku_stock.stock <= 0 || el.sku_stock.stock == null) {
            this.skuStock = null
            this.skuData.attr[index].val[ind].emptyStock = true
          }
        }
      })
      this.skuData.attr[index].val[ind].hidden = false
      if(isHidden) {
        this.skuData.attr[index].val[ind].hidden = true
      }
    },
    // 点击 BTN
    handleBtn(){
      if(this.skuStock && this.number > this.skuStock.stock && this.skuStock.stock) {
        this.$notify({ type: 'warning', message: '购买数量超出剩余库存' })
        return false
      }

      if(this.title == '加入购物车') {
        this.addCart()
      } else if (this.title == '修改规格') {
        this.$emit('edit', {barcode: this.skuStock.barcode, num: this.number})
      } else {
        if(this.haggle == 1) {
          my_total().then(res => {
            if(res) {
              this.$router.push({path:`/zh/haggleDetail/${this.goodsId}`,query:{id: this.haggle_id}})
              localStorage.setItem('goodsSKU',JSON.stringify(this.goodsSKU))
              localStorage.setItem('haggleGoods',JSON.stringify(this.haggleInfo))
            }
          })
        } else {
          this.buyNow()
        }
      }
      
    },
    // 加入购物车
    addCart() {
      let params = {
        goods_id: this.goodsId,
        barcode: this.skuStock.barcode,
        buy_number: this.number
      }
      addCart(params).then(res => {
        if(res) {
          this.$emit('success')
          let timer = setTimeout( () => {
            this.$store.commit('addCart', this.number)
            clearTimeout(timer)
          }, 600)
        }
      })
    },
    // 立即购买
    buyNow() {
      let params = {
        buyId: this.skuId,
        buyNum: this.number
      }
      this.$router.push({ path: '/zh/buynow', query: params  })
    },
    getSkuHandle() {
      // 获取SKU
      let params = {}
      if(this.isPre) {
        params.pre = 1
      }
      getSku(this.goodsId,params).then(res => {
        this.initRes(res)
      }).finally(() => {
        this.isLoading = false
      })
    },
    initRes(res) {
      if(res) {
        this.skuData = res.data
        // 判断是否有条形码和数量  --  购物车修改，默认选中指定规格
        this.initSku()
        if(this.barcode && this.num) {
          // 设置数量和选中
          this.number = this.num
          setTimeout(() => {
            let attr_symbol = []
            this.skuData.sku.forEach(el => {
              if(el.barcode == this.barcode) {
                attr_symbol = el.attr_symbol_path.split(',')
              }
            })
            this.skuData.attr.forEach((el, index) => {
              el.val.forEach(ll => {
                if(attr_symbol.indexOf(ll.symbol + '') > -1) {
                  this.handleActive(el, ll, index)
                }
              })
            })
          }, 200)
        }
      }
    },
    initSku() {
      // 默认第一个规格图
      // 单多规格判断
      this.selectSku = []
      if(this.skuData.sku.length >= 1) {
        this.skuImg = this.skuData.sku[0].sku_pic_url
        this.goodsPrice = this.skuData.sku[0].price
        this.costPrice = this.skuData.sku[0].cost_price
        this.isEmpty = this.skuData.sku[0].sku_stock && this.skuData.sku[0].sku_stock == 0 ? true : false
      }

      if(this.skuData.attr.length == 1) {
        // 单规格库存判断
        this.skuId  = this.skuData.sku[0].id
        this.skuStock = this.skuData.sku[0]

        this.skuData.attr[0].val.forEach((el, index) => {
          el.emptyStock = false
          el.hidden = true
          this.skuData.sku.forEach(ll => {
            if(el.symbol == ll.attr_symbol_path) {
              el.hidden = false
              if(this.haggle == 1) {
                this.haggleInfo.bargain_goods_sku.forEach(item => {
                  if(item.goods_sku.barcode == ll.barcode) {
                    this.haggle_id = item.id
                    this.goodsSKU = item
                  }
                })
              }
              if(!ll.sku_stock || ll.sku_stock.stock <= 0 || ll.sku_stock.stock == null) {
                el.emptyStock = true
                if(index == 0) {
                  this.isEmpty = true
                }
              }
            }
            if (this.$route.query.pre == 1 && this.skuData.attr[0].val.length != this.skuData.sku.length) {
                this.skuData.attr[0].val = this.skuData.attr[0].val.filter(item=>item.hidden!==true)
            }
          })
         if(index == 0 && !el.hidden ) {
            this.selectSku.push(el.attr_title)
            el.active = true
          }else{
            el.active = false
          }
        })
        
      }else{
        // 多规格库存判断
        this.skuData.attr.forEach(el => {
          el.val.forEach(ll => {
            ll.active = false
            ll.emptyStock = false
            ll.hidden = false
          })
        })
        setTimeout(() => {
          this.skuData.attr.forEach((el, index) => {
            if(el.val.length > 0) {
              this.handleActive(el, el.val[0], index)
            }
          })
        }, 200)
      }
    },
    getBatchHandle() {
      // 获取批次时间
      getBatch().then(res => {
        if(res) {
          this.batch = res.data
        }
      })
    },
    // 图片预览
    imagePreview() {
      let arr = []
      this.skuData.sku.forEach(item => {
        arr.push(item.sku_pic_url)
      })
      ImagePreview({
        images:arr,
        closeable: true,
        startPosition: this.imgIndex,
        onChange: index => {
          this.imgIndex = index
          this.selectSku = []

          if(this.skuData.attr.length == 1) {
            this.handleActive(this.skuData.attr[0], this.skuData.attr[0].val[index], 0)
          } else {
            this.skuData.sku[index].attr_symbol_path.split(',').forEach(el => {
              this.skuData.attr[0].val.forEach(item => {
                if(item.symbol == el) {
                  this.handleActive(this.skuData.attr[0],item, 0)
                }
              })
              setTimeout(() => {
                this.skuData.attr[1].val.forEach(item => {
                  if(item.symbol == el) {
                    this.handleActive(this.skuData.attr[1],item, 1)
                  }
                })
              }, 200);
            })
          }
        }
      })
    },
    // 获取砍价商品详情
    getHaggleInfo() {
      haggle_detail(this.$route.query.id).then(res => {
        if(res) {
          this.haggleInfo = res.data
          this.getSkuHandle()
        }
      })
    },
  },
  created() {
    this.getBatchHandle()
  },
  watch: {
    show(value){
      this.showSku = value
      this.number = 1
    },
    off(val) {
      if (val) {
        if(this.haggle == 1) {
          this.getHaggleInfo()
        } else {
          this.getSkuHandle()
        }
      }
    },
    goodsId: {
      handler(val) {
        if(val != '') {
          // 获取规格
          this.isLoading = true
          this.isEmpty = false

          // 判断是否时预售
          let path = ['/zh/presale', '/zh/subsale']
          if(path.indexOf(this.$route.path) != -1 || this.$route.query.pre && this.$route.query.pre == 1 || this.$route.query.s == 'presale') {
            this.isPre = true
          } else {
            this.isPre = false
          }
          if(this.haggle == 1) {
            this.getHaggleInfo()
          } else {
            this.getSkuHandle()
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.m-sku {
  max-height: 640px;
  border-radius:5px 5px 0px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-top: 20px;
  padding-bottom:80px;
  .sku-box {
    height: calc(100% - 76px);
    overflow-y: auto;
  }
  .sku-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:20px;
    &>img {
      width: 110px;
      height: 110px;
      display: inline-block;
      margin-right: 12px;
    }
    .sku-price {
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &>p:nth-of-type(1){
        margin-bottom: 4px;
        font-size: 12px;
        &>span:nth-of-type(1){
          color: #ED2A24;
          font-weight: 400;
        }
        &>span:nth-of-type(2){
          color: #ED2A24;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px;
        }
        &>span:nth-of-type(3){
          color: #999999;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
      &>p:nth-of-type(2) {
        font-size: 14px;
        color: #333333;
        letter-spacing: 0.37px;
      }
    }
  }
  .sku-value {
    margin-top: 10px;
    .title {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 20px;
    }
    .values {
      margin-top: 12px;
      overflow-y: auto;
      padding-top: 5px;
      &>span {
        display: inline-block;
        padding: 6px 16px;
        border: 1px solid #666666;
        margin-right:12px ;
        font-size: 14px;
        color: #666666;
        border-radius: 2px;
        margin-bottom:12px;
      }
      .emptyStock{border-style: dashed !important;border-color: #888 !important;color: #888 !important;position: relative;
        &::after{content: '预售';position: absolute;top:-5px;right:-5px;padding: 2px 4px;font-size: 8px;color:#fff;background-color: #ED2A24;
        border-radius: 2px;}
      }
      .active {
        color: #ED2A24 !important;
        border-color: #ED2A24 !important;
      }
      .hidden{color:#666666;border-color: #666666;}
    }
  }
  .sku-num {
    margin: 10px 0;
    .title {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 20px;
    }
    .number {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .number-btn {
        width: 30px;
        height: 30px;
      }
      &>input {
        border: none;
        outline: none;
        width: 60px;
        height: 30px;
        text-align: center;
        border-radius: 0;
        background-color: #f2f3f5;
      }
    }
  }
  .sku-button {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    padding: 0 15px;
    /deep/ .van-button{
      font-size: 16px;
      font-weight:500;
      color:#ffffff;
      line-height:25px;
      width:300px;
      height:40px;
      border-radius: 40px;
      margin:0 auto;
    }
  }
  
  .pre-box{width:100%;background-color: #f7f7f7;border-radius: 4px;
    .tips {
      display: grid;
      padding: 10px 16px;
      grid-template-columns: 50px 240px;
      font-size:12px;
      font-weight:400;
      color:rgba(102,102,102,1);
      >p {
        line-height: 17px;
      }
    }
  }
}
</style>
